

































import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
    props: {
        iconStyle: {
            type: String,
            default: "dark",
            required: false,
        },
    },
    computed: mapState([
        "resumeLink",
    ]),
});
