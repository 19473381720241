
































import Vue from "vue";
import { mapState } from "vuex";
import LogoTextBlock from "@/components/blocks/LogoTextBlock.vue";
import experienceList from "@/assets/contents/EducationContent";

export default Vue.extend({
    name: "EducationSection",
    components: {
        LogoTextBlock,
    },
    props: {
    },
    data() {
        return {
            sectionTitle: "Education",
            experienceList,
            imageType: "logo",
            hideBullet: false,
        };
    },
    computed: mapState([
        "oneColumnGrid",
    ]),
});
