








































import Vue from "vue";

export default Vue.extend({
    name: "LogoTextBlock",
    components: {
    },
    props: {
        title: {
            type: String,
            required: false,
            default: "This is title",
        },
        role: {
            type: String,
            required: false,
            default: "This is role",
        },
        descriptions: {
            type: Array,
            required: false,
            default: (): string[] => [
                "AAAAAAAAAAAAAAAAAA",
                "BBBBBBBBBBBBBBBBBB",
                "CCCCCCCCCCCCCCCCCC",
            ],
        },
        startDate: {
            type: String,
            required: false,
            default: "",
        },
        endDate: {
            type: String,
            required: false,
            default: "",
        },
        logo: {
            type: String,
            required: false,
            default: "",
        },
        hideBullet: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
        };
    },
    computed: {
        startToEndDateText(): string {
            if (this.startDate === "") {
                return "";
            }
            if (this.endDate === "") {
                return `${this.startDate} - Present`;
            }
            if (this.startDate === this.endDate) {
                return this.startDate;
            }
            return `${this.startDate} - ${this.endDate}`;
        },
    },
});
