












import Vue from "vue";

export default Vue.extend({
    props: {
        src: {
            type: String,
            required: true,
        },
        altText: {
            type: String,
            default: "My Profile Image",
            required: false,
        },
        size: {
            type: Number,
            default: 10,
            required: false,
        },
    },
});
