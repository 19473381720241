


































import Vue from "vue";
import { mapState } from "vuex";
import MyLinks from "@/components/MyLinks.vue";

export default Vue.extend({
    components: {
        MyLinks,
    },
    computed: {
        currYear(): number {
            return new Date().getFullYear();
        },
        ...mapState([
            "resumeLink",
        ]),
    },

});
